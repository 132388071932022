@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Segoe+UI+Emoji&display=swap');

.datatable-container {
  font-family: 'Open Sans', ;
  font-size: 12px;
}

.table-header {
  font-size: 18px;
  text-align: left;
  margin-bottom: 16px;
  color: #06163A;
  font-family:  'Open Sans'; 
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: #06163A !important;
  color: white !important;
  text-align: center;
}

.datatable-responsive {
  font-family: 'Open Sans';
  font-size: 12px;

  text-align: center;
}
/* .p-datatable .p-datatable-tbody > tr > td {
  text-align: center; 
} */
/* 
.p-datatable .p-datatable-header {
  text-align: center; 
} */