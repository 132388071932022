:root {
  --primary-color: #44AEF5;
  --background-color: #6564BF;
  --card-background-color: #B491C9;
  --text-color: #d18a8a;
  --error-color: #cf6679;
  --button-hover-color: #0481f7;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-content {
  display: flex;
  width: 80%;
  max-width: 1200px;
  box-shadow: 0 0 20px rgba(43, 39, 39, 0.7);
  border-radius: 15px;
  overflow: hidden;
}

.login-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: var(--card-background-color);
}

.login-form h1 {
  margin-bottom: 10px;
  font-size: 2.5rem;
  color: #fff;
}

.login-form p {
  margin-bottom: 30px;
  font-size: 1rem;
  color:  #fff;
}

.login-form form {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 300px;
}

.login-form input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #444;
  border-radius: 15px;
  font-size: 12px; 
  background-color: #ffffff;
  color: var(--text-color);
}

.login-form .input-error {
  border-color: var(--error-color);
}

.login-form .error-message {
  color: var(--error-color);
  font-size: 0.875rem;
  margin-bottom: 10px;
}

.login-button {
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  background-color: #434191;
  color: #fff;
  font-size: 16px;
  transition: all 0.3s ease;
}

.login-button:hover {
  background-color: var(--button-hover-color);
}

.forgot-password {
  padding: 8px;
  margin-top: 10px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  background-color: #434191;
  color: #fff;
  font-size: 16px;
  transition: all 0.3s ease;
}

.forgot-password:hover {
  text-decoration: underline;
}

.login-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url('../../assets/images/loginimg.PNG') no-repeat center center;
  background-size: cover;
}

.login-image img {
  max-width: 100%;
  height: auto;
  border-radius: 0 15px 15px 0;
}
